import React, { FC, InputHTMLAttributes } from 'react';
import { InputProps } from './Input.props';
import { cn } from 'utils';
import { ErrorMessage, useField } from 'formik';
import Text from '../Text';
import { Tooltip } from 'react-daisyui';
import Info from 'assets/SVG/info';

const InputView: FC<InputProps> = ({
  label,
  className,
  labelContainer,
  inputContainer,
  name,
  tooltip,
  hideError = false,
  value,
  readonly,
  disabled = false,
  required = false,
  ...inputProps
}) => {
  const [field, meta, helpers] = useField(name);

  React.useEffect(() => {
    const input = document.getElementById(name);
    if (input) {
      input.addEventListener('mousewheel', (event: any) => {
        event.target.blur();
      });
    }
  }, [name]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    helpers.setValue(inputValue);
    if (inputProps.onChange) inputProps.onChange(inputValue);

    if (inputProps.type === 'number') {
      const parsedValue = parseFloat(inputValue);
      if (!isNaN(parsedValue)) {
        helpers.setValue(parsedValue);
        if (inputProps.onChange) inputProps.onChange(parsedValue);
      }
    }
  };

  return (
    <div className={cn(`flex flex-col gap-2 ${className}`)}>
      <div className={labelContainer}>
        <label
          className={cn(required ? 'required' : '')}
          htmlFor={inputProps.id}>
          {label}
        </label>
        {tooltip && (
          <Tooltip message={tooltip}>
            <Info />
          </Tooltip>
        )}
      </div>
      <div className={cn(`flex flex-col ${inputContainer}`)}>
        <input
          disabled={disabled}
          readOnly={readonly || false}
          value={
            field?.value !== undefined
              ? field.value
              : value !== undefined
                ? value
                : ''
          }
          name={name}
          {...inputProps}
          className={cn(`input input-bordered w-full ${inputContainer}`)}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={handleChange}
        />
        {!hideError && meta.error && meta.touched && (
          <Text className="text-red-500  mt-1">{meta.error}</Text>
        )}
      </div>
    </div>
  );
};

export default InputView;
