import { FC, useState } from 'react';
import SizeWeight from '../tabs/SizeWeight';
import { PackagesFormProps } from './PackagesForm.props';
import { FieldArray, useFormikContext } from 'formik';
import { defaultPackageObject } from 'utils/shipOrder';
import usePackageFormHelper, {
  PackageData,
} from './hooks/packageFormHelperHook';
import RemoveIcon from 'assets/SVG/removeIcon';
import AddIcon from 'assets/SVG/addIcon';
import ModalReference from 'helperClasses/modalReference';
import AlertModal from 'components/primitives/AlertModal';
import Button from 'components/primitives/Button';

const PackagesForm: FC<PackagesFormProps> = ({ mode, limit = 10 }) => {
  const { addNewPackage, hasMoreThanOnePackage, deletePackage, packages } =
    usePackageFormHelper();

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const deleteAlertReference = new ModalReference();

  const enableAddPackage =
    mode == 'process' || mode == 'edit' || mode == 'create';

  const isCreateMode = mode == 'create';

  const disableEdit = mode != 'create' && mode != 'process' && mode != 'edit';

  const enableDeletePackage = isCreateMode || hasMoreThanOnePackage;

  return (
    <div className="space-y-4">
      <FieldArray name="packages">
        {({ form }) => {
          return (
            <div className="space-y-8 w-full">
              {packages?.map((_: any, index: any) => {
                const prefixName = `packages[${index}]`;
                return (
                  <div className="w-full flex flex-row">
                    <SizeWeight
                      prefixName={prefixName}
                      mode={mode}
                      disable={disableEdit}
                      index={index}
                      containerClassName="rounded-s-md"
                    />
                    {enableDeletePackage && !disableEdit && (
                      <div
                        className="bg-red-200 flex justify-center items-center px-4 cursor-pointer rounded-e-md"
                        onClick={() => {
                          setDeleteIndex(index);
                          deleteAlertReference.open();
                        }}>
                        <RemoveIcon />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        }}
      </FieldArray>
      {enableAddPackage && packages.length < limit && (
        <Button
          className="bg-blue-50 w-full hover:bg-blue-200 hover:text-white group border-none"
          type="button"
          onClick={() => {
            addNewPackage();
          }}>
          <AddIcon className="group-hover:fill-white" />
          {packages.length == 0 ? 'Create Package' : 'Create Another Package'}
        </Button>
      )}

      {packages.length == limit && (
        <div className=" text-sm">
          You have reached the maximum number of packages.
        </div>
      )}

      <AlertModal
        ref={deleteAlertReference.ref}
        title="Delete Package"
        description="Are you sure you want to delete this package?"
        actions={[
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              deletePackage(deleteIndex!);
              deleteAlertReference.close();
            }}
            className="mx-2 rounded-btn border-blue-200 font-jakarta  text-md normal-case">
            Yes
          </Button>,
          <Button
            type="button"
            onClick={() => {
              deleteAlertReference.close();
            }}
            variant="solid"
            className="mx-2 rounded-btn border-blue-200 font-jakarta  text-md normal-case">
            No
          </Button>,
        ]}
      />
    </div>
  );
};

export default PackagesForm;
