import React, { FC, ReactNode } from 'react';

import { HeaderViewProps } from './Header.props';
import { useLocation } from 'react-router-dom';

import HeaderView from './Header.view';
import { NavItems } from '../../../constants/sideBarMenuItems';

const HeaderContainer: React.FC<HeaderViewProps> = ({
  children,
  title,
  enableGoBack = false,
}) => {
  const location = useLocation();
  const defaultTitle =
    title || NavItems.find((item) => item.path === location.pathname)?.title;

  return (
    <HeaderView enableGoBack={enableGoBack} title={defaultTitle}>
      {children}
    </HeaderView>
  );
};

export default HeaderContainer;
