import React, { FC, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';

import GeneralInfo from './GeneralInfo';
import ClientInfo from './ClientInfo';
import SizeWeight from './SizeWeight';
import { useClientsHook } from 'hooks/useClients';
import { useUsers } from 'hooks/useUsers';
import { UserRole } from 'types/user';
import { GeneralInfoProps } from '../OrderForm.props';
import PackagesForm from '../PackagesForm/PackagesForm.view';

const TabGeneralInfo: FC<GeneralInfoProps> = (props) => {
  const [page, setPage] = useState(1);
  const { clients, loading } = useClientsHook();
  const formik = useFormikContext<any>();
  const role = UserRole.EMPLOYEE;
  const { users, loading: userLoading } = useUsers({
    page,
    role,
    designation: 'PM',
  });
  const mode = props.mode;
  return (
    <div className="container mx-auto py-2 px-5 mb-8">
      <div>
        <GeneralInfo
          clients={clients}
          loading={loading || userLoading}
          users={users}
          mode={props.mode}
        />
        <PackagesForm mode={props.mode} />
      </div>
    </div>
  );
};

export default TabGeneralInfo;
