import React, { FC, useState, useEffect } from 'react';
import noImg from 'assets/SVG/no-img-placeholder.svg';
import { cn } from 'utils';

type ZoomableImageProps = {
  src: string;
  alt?: string;
};

const ZoomableImage: FC<ZoomableImageProps> = ({ src, alt = 'Image' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'hidden';
    } else {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = '';
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);

  return (
    <>
      {src ? (
        <>
          <img
            src={src}
            alt={alt}
            className="w-10 h-10 rounded cursor-zoom-in object-cover border border-gray-400"
            draggable="false"
            onClick={openModal}
          />
          <div
            className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-100 ease-out ${isModalOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
            onClick={closeModal}>
            <div
              className={`absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-200 ${isModalOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}></div>
            <div
              className={`relative transform transition-transform duration-200 ease-out ${isModalOpen ? 'scale-100' : 'scale-50'}`}
              onClick={(e) => e.stopPropagation()}>
              <button
                type="button"
                aria-label="Close"
                className="absolute top-4 right-4 w-10 h-10 flex items-center justify-center text-white focus:outline-none bg-black bg-opacity-30 rounded-full hover:bg-opacity-50 transition duration-200 ease-in-out"
                onClick={closeModal}>
                &times;
              </button>
              <img
                src={src}
                alt={alt}
                className="rounded-lg object-contain max-w-screen-lg max-h-[90vh] shadow-2xl"
                draggable="false"
              />
            </div>
          </div>
        </>
      ) : (
        <img
          src={noImg}
          alt="No Image Available"
          className="w-10 h-10 rounded object-cover border border-gray-400"
          draggable="false"
        />
      )}
    </>
  );
};

export default ZoomableImage;
