import React, { FC, useEffect } from 'react';

import Input from 'components/primitives/Input';
import FormikSelectView from 'components/primitives/FormikSelect/FormikSelect.view';
import FormikRadioButton from 'components/primitives/FormikRadioButton';
import { useFormikContext } from 'formik';
import FormikDateRangepicker from 'components/primitives/FormikDateRangepicker';
import FormikDatepicker from 'components/primitives/FormikDatepicker';
import { GeneralInfoViewProps } from '../OrderForm.props';
import { useMutation } from '@tanstack/react-query';
import { STATES } from 'stores/states/state';
import FormikRadioInput from 'components/primitives/FormikRadioInput';
import ClientInfo from './ClientInfo';
import FormikTextArea from 'components/primitives/FormikTextArea';

const GeneralInfo: FC<GeneralInfoViewProps> = ({
  clients,
  loading,
  users,
  mode = 'create',
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const className = 'flex-col my-1 max-w-sm';
  useEffect(() => {
    if (values.client && clients.length > 0) {
      onChangeClient(values.client);
    }
  }, [values.client, clients]);

  useEffect(() => {
    setFieldValue('contact.contactName', values.contact.contactName || '');
    setFieldValue('contact.phoneNumber', values.contact.phoneNumber || '');
  }, [values.isClientBillToAccount]);

  const onChangeClient = (clientId: string) => {
    if (clients.length > 0) {
      const clientObj = clients.find((val) => val._id === clientId);
      const primaryContact = clientObj?.contact?.find(
        (val) => val.primaryContact,
      );
      setFieldValue('clientInfo.name', clientObj?.name || '');
      setFieldValue('clientInfo.location', clientObj?.location || null);
      setFieldValue(
        'clientInfo.billingAddress',
        clientObj?.billingAddress || '',
      );
      setFieldValue(
        'clientInfo.physicalAddress',
        clientObj?.physicalAddress || null,
      );
      setFieldValue('address.state', values.address.state || null);
      setFieldValue('clientInfo.contact', primaryContact?.contactName || '');
      setFieldValue('clientInfo.phone', primaryContact?.phone || null);
      setFieldValue('clientInfo.email', primaryContact?.email || '');
    }
  };

  const clearItems = () => {
    setFieldValue('orderDetail', []);
    localStorage.removeItem('orderItems');
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* General information */}
        <div className="flex flex-col gap-4 my-4">
          <h3 className="font-jakarta">General Info</h3>
          <FormikSelectView
            disabled={mode !== 'create'}
            label="Client"
            name="client"
            className={className}
            labelContainer="col-span-1 required"
            value={values.client}
            onChange={(_id) => {
              onChangeClient(_id);
              clearItems();
            }}
            id="client">
            <option value="">Select a client</option>
            {clients.map((client) => (
              <option value={client._id}>{client.name}</option>
            ))}
          </FormikSelectView>
          <Input
            disabled={mode === 'ship'}
            label="Account Number"
            id="accountNumber"
            name="accountNumber"
            type="text"
            placeholder="Enter account number"
            className={className}
            labelContainer="col-span-1"
            inputContainer="col-span-2 "
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
          />

          <div className="flex flex-col gap-4 font-light my-2">
            <label className="w-1/3 required">Bill To Account</label>
            <div className="w-2/3">
              <FormikRadioInput
                disabled={mode === 'ship'}
                onClick={(value) =>
                  setFieldValue('isClientBillToAccount', value)
                }
                items={[
                  {
                    label: 'Tenant',
                    value: true,
                  },
                  {
                    label: 'Client',
                    value: false,
                  },
                ]}
                name={'isClientBillToAccount'}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 font-light my-3">
            <label className="w-1/3 required">Returnable</label>
            <div className="w-2/3">
              <FormikRadioInput
                onClick={(value) => setFieldValue('isReturnable', value)}
                items={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                name={'isReturnable'}
              />
            </div>
          </div>
          <FormikSelectView
            disabled={mode === 'ship'}
            label="Project Manager"
            id="projectManager"
            name="projectManager"
            className={className}
            value={values.projectManager}
            labelContainer="col-span-1 required"
            fieldContainer="col-span-2 max-w-xs">
            <option value="">Select a project manager</option>
            {users.map((user) => (
              <option value={user._id}>
                {user.firstName} {user.lastName}
              </option>
            ))}
          </FormikSelectView>
        </div>
        {/* Shipping */}
        <div className="flex flex-col gap-4 my-4">
          <h3 className="font-jakarta">Shipping Information</h3>
          <div className="flex gap-4 max-md">
            <FormikDatepicker
              disabled={mode === 'ship'}
              isRequired
              label="Ship Date"
              name="shipDate"
              id="shipDate"
              labelProps={`col-span-1 ${mode !== 'create' ? 'required' : ''}`}
              containerProps={className}
              inputContainer="col-span-2"
              wrapperClass={'col-span-2'}
            />
            <FormikSelectView
              disabled={mode === 'ship'}
              label="Shipping Preference"
              id="shippingPreference"
              name="shippingPreference"
              className={className}
              value={values.shippingPreference}
              labelContainer="col-span-1 required"
              fieldContainer="col-span-2 max-w-xs">
              <option key="empty" value="">
                Select an option
              </option>
              <option key={'fedex'} value="Fedex">
                Fedex
              </option>
              <option key={'ups'} value="UPS">
                UPS
              </option>
              <option key={'usps'} value="USPS">
                USPS
              </option>
              <option key={'courier'} value={'Courier'}>
                Courier
              </option>
              <option key={'frieght'} value={'Freight'}>
                Freight
              </option>
            </FormikSelectView>
          </div>
          <Input
            disabled={mode === 'ship'}
            label="Delivery Address"
            id="deliveryAddress"
            name="address.addressLine1"
            type="text"
            placeholder="e.g., 123 Main St."
            className={className}
            labelContainer="col-span-1 required"
            inputContainer="col-span-2"
          />

          <Input
            disabled={mode === 'ship'}
            label="Apartment, Suite etc."
            id="apartment"
            name="address.addressLine2"
            type="text"
            placeholder="e.g., Apt 4B"
            className={className}
            labelContainer="col-span-1"
            inputContainer="col-span-2"
          />

          <Input
            disabled={mode === 'ship'}
            label="City"
            id="city"
            name="address.city"
            type="text"
            placeholder="e.g., New York"
            className={className}
            labelContainer="col-span-1 required"
            inputContainer="col-span-2"
          />
          <div className="flex gap-4 max-w-sm">
            <FormikSelectView
              label="State"
              id="state"
              disabled={mode === 'ship'}
              name="address.state"
              className={className}
              labelContainer="col-span-1 required"
              fieldContainer="col-span-2 max-w-xs h-20 max-h-20"
              fieldClass="h-10"
              value={values.address.state}>
              {STATES.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </FormikSelectView>

            <Input
              disabled={mode === 'ship'}
              label="Zip Code"
              id="zipcode"
              name="address.zip"
              type="text"
              placeholder="e.g., 10001"
              className={className}
              labelContainer="col-span-1 required"
              inputContainer="col-span-2"
            />
          </div>
        </div>
        {/* Dates and Deliveries */}
        <div className="flex flex-col gap-4 my-4">
          <h3 className="font-jakarta">Dates and Deliveries</h3>
          <FormikDatepicker
            disabled={mode === 'ship'}
            isRequired
            label="Arrival Date"
            name="arrivalDate"
            id="arrivalDate"
            labelProps="col-span-1 required"
            containerProps={className}
            inputContainer="col-span-2"
            wrapperClass={'col-span-2'}
          />

          <FormikDatepicker
            disabled={mode === 'ship'}
            isRequired
            label="Pickup Date"
            name="pickupDate"
            id="pickupDate"
            labelProps="col-span-1 required"
            containerProps={className}
            inputContainer="col-span-2"
            wrapperClass={'col-span-2'}
          />

          <FormikDatepicker
            disabled={mode === 'ship'}
            isRequired
            label="Return Date"
            name="returnDate"
            id="returnDate"
            labelProps="col-span-1 "
            containerProps={className}
            inputContainer="col-span-2"
            wrapperClass={'col-span-2'}
          />

          <FormikDatepicker
            disabled={mode === 'ship'}
            label="Prep By Date"
            name="prepByDate"
            id="prepByDate"
            labelProps="col-span-1"
            containerProps={className}
            wrapperClass={'col-span-2'}
            inputContainer="col-span-2"
          />
          <FormikDateRangepicker
            disabled={mode === 'ship'}
            containerClass={className}
            name="advanceWarehouseDate"
            label="Adv. Wrhse Dates"
            labelClass="col-span-1"
            fieldContainer="col-span-2 max-w-xs"
            fieldClass="max-w-full"
          />
        </div>
        {/* Event Information */}
        <div className="flex flex-col gap-4 my-4">
          <h3 className="font-jakarta">Event Information</h3>
          <div className="grid gap-4">
            <Input
              disabled={mode === 'ship'}
              label="Event"
              id="event"
              name="event"
              type="text"
              placeholder="e.g., Annual Conference 2024"
              className={className}
              labelContainer="col-span-1 required"
              inputContainer="col-span-2"
            />
          </div>

          <div className="grid gap-4">
            <Input
              label="Recipient Name"
              id="recipient.name"
              name="recipient.name"
              type="text"
              placeholder="e.g., Jane Smith"
              className={className}
              labelContainer="col-span-1 "
              inputContainer="col-span-2 "
            />

            <Input
              label="Recipient Email"
              id="recipient.email"
              name="recipient.email"
              type="text"
              placeholder="e.g., jane.smith@example.com"
              className={className}
              labelContainer="col-span-1 "
              inputContainer="col-span-2 "
            />
            <Input
              label="Recipient Phone"
              required
              id="recipient.phone"
              name="recipient.phone"
              type="text"
              placeholder="e.g., (123) 456-7890"
              className={className}
              labelContainer="col-span-1 "
              inputContainer="col-span-2 "
            />
          </div>
        </div>
        {/* Client Info Section */}
        <div className="flex flex-col gap-4 my-4 max-w-sm">
          <h3 className="font-jakarta">Client Info</h3>
          <Input
            disabled={true}
            id="clientName"
            name="clientInfo.name"
            label="Client Name"
            type="text"
            labelContainer="col-span-1"
            inputContainer="col-span-2"
            placeholder="e.g., Acme Corp."
            className="flex-col my-1 grid-cols-1 col-span-1"
            value={values.name}
          />
          <Input
            disabled={true}
            id="location"
            name="clientInfo.location"
            label="Location"
            type="text"
            labelContainer="col-span-1"
            inputContainer="col-span-2"
            placeholder="e.g., New York, NY"
            className="flex-col my-1 grid-cols-1 col-span-1"
            value={values.location}
          />
          <Input
            disabled={true}
            label="Billing Address"
            id="billingAddress"
            name="clientInfo.billingAddress"
            type="text"
            labelContainer="col-span-1"
            inputContainer="col-span-2"
            placeholder="e.g., 123 Main St, Suite 500"
            className="flex-col my-1 grid-cols-1 col-span-1"
            value={values.billingAddress}
          />
          <Input
            disabled={true}
            label="Physical Address"
            id="physicalAddress"
            name="clientInfo.physicalAddress"
            type="text"
            labelContainer="col-span-1"
            inputContainer="col-span-2"
            placeholder="e.g., 456 Elm St"
            className="flex-col my-1 grid-cols-1 col-span-1"
            value={values.physicalAddress}
          />
        </div>

        {/* Primary Contact Section */}
        <div className="flex flex-col gap-4 my-4 max-w-sm">
          <h3 className="font-jakarta">Primary Contact</h3>
          <Input
            disabled={true}
            label="Contact Name"
            id="contact"
            name="clientInfo.contact"
            type="text"
            labelContainer="col-span-1"
            inputContainer="col-span-2"
            placeholder="e.g., John Doe"
            className="flex-col my-1 grid-cols-1 col-span-1"
            value={values.contact}
          />
          <Input
            disabled={true}
            label="Phone Number"
            id="phone"
            name="clientInfo.phone"
            type="tel"
            labelContainer="col-span-1"
            inputContainer="col-span-2"
            placeholder="e.g., (123) 456-7890"
            className="flex-col my-1 grid-cols-1 col-span-1"
            value={values.phone}
          />
          <Input
            disabled={true}
            label="Email Address"
            id="email"
            name="clientInfo.email"
            type="text"
            labelContainer="col-span-1"
            inputContainer="col-span-2"
            placeholder="e.g., john.doe@example.com"
            className="flex-col my-1 grid-cols-1 col-span-1"
            value={values.email}
          />
          <div className="my-1 col-span-1 h-12"></div>
        </div>
      </div>
      <FormikTextArea
        className="w-full rounded-lg mb-4"
        disabled={mode === 'ship'}
        name="notes"
        label="Notes"
        required
        id="notes"
        rows={4}
        placeholder="Add ship order notes here..."
      />
    </>
  );
};

export default GeneralInfo;
