import React, { FC } from 'react';

import { MarkOrderShippedViewProps } from './MarkOrderShipped.props';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-daisyui';
import SuccessCheckMark from 'svg/successCheckMark';
import Button from 'components/primitives/Button';
import OrderForm from 'components/modules/OrderForm';
import AlertModal from 'components/primitives/AlertModal';
import { useShipOrderDetail } from 'hooks/useShipOrderDetail';

const MarkOrderShippedView: FC<MarkOrderShippedViewProps> = ({
  submitForm,
  successModalRef,
  id,
  initialValues,
  isPending,
}) => {
  const navigate = useNavigate();
  const { shipOrderDetail } = useShipOrderDetail(id);

  const successModal = () => (
    <div className="font-sans">
      <AlertModal
        title="Order Is Shipped"
        ref={successModalRef}
        infoIcon={<SuccessCheckMark />}
        actions={[
          <Button
            variant="outline"
            onClick={() =>
              navigate('/ship-orders', {
                replace: true,
              })
            }
            className="mx-2 rounded-btn border-blue-200 font-jakarta  text-md normal-case">
            Go To Orders List
          </Button>,
          <Button
            variant="solid"
            className="mx-2 rounded-btn border-blue-200 font-jakarta  text-md normal-case"
            onClick={() =>
              navigate('/ship-orders/summary/' + id, {
                replace: true,
              })
            }>
            Go To Summary
          </Button>,
        ]}
      />
    </div>
  );

  return (
    <>
      {id && initialValues && (
        <OrderForm
          title={`Mark as Shipped - Order #${shipOrderDetail?.orderNumber}`}
          initialValues={initialValues}
          isEditable={false}
          onSubmit={submitForm}
          mode="ship"
          loading={isPending}
        />
      )}
      {successModal()}
    </>
  );
};

export default MarkOrderShippedView;
