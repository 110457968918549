import React, { FC } from 'react';

import { LoadingProps } from './Loading.props';

const LoadingView: FC<LoadingProps> = (props) => {
  return (
    <div className="flex w-full h-full flex-col gap-8 justify-center items-center md:py-14">
      <span className="loading loading-ring loading-lg text-primary-500"></span>
    </div>
  );
};

export default LoadingView;
