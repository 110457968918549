import React, { FC, useEffect } from 'react';

import { SizeProps } from './Size.props';
import Text from '../Text';
import { Field, useField, useFormikContext } from 'formik';
import Input from '../Input';
import FormikSelectView from '../FormikSelect/FormikSelect.view';

const SizeView: FC<SizeProps> = ({
  width = 0,
  depth = 0,
  height = 0,
  disable = false,
  prefixName = 'package',
  mode,
  index = 0,
}): JSX.Element => {
  const [field, meta] = useField('size');
  const { setFieldValue, values } = useFormikContext<any>();
  const errors = meta?.error && meta.touched ? Object.entries(meta.error) : [];
  return (
    <div className="flex w-full justify-start items-center gap-2">
      <Input
        type="number"
        step=".01"
        name={`${prefixName}.size.width`}
        min="0"
        disabled={disable}
        hideError
        value={field.value !== '' ? field.value : width}
        inputContainer={`max-w-none w-16 p-0`}
        className={`max-w-xs gap-0`}
        style={{ outline: 'none', textAlign: 'center' }}
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        placeholder="W"
        onClick={(event) => {
          const target = event.target as HTMLInputElement;
          target.focus();
          target.select();
        }}
      />
      <span className="">x</span>
      <Input
        type="number"
        disabled={disable}
        step=".01"
        name={`${prefixName}.size.height`}
        min="0"
        hideError
        value={field.value !== '' ? field.value : height}
        inputContainer={`max-w-none w-16 p-0`}
        className={`max-w-xs gap-0`}
        style={{ outline: 'none', textAlign: 'center' }}
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        placeholder="H"
        onClick={(event) => {
          const target = event.target as HTMLInputElement;
          target.focus();
          target.select();
        }}
      />
      <span className="">x</span>
      <Input
        type="number"
        disabled={disable}
        step=".01"
        name={`${prefixName}.size.depth`}
        min="0"
        hideError
        value={field.value !== '' ? field.value : depth}
        inputContainer={`max-w-none w-16 p-0`}
        className={`max-w-xs  gap-0`}
        style={{ outline: 'none', textAlign: 'center' }}
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        placeholder="D"
        onClick={(event) => {
          const target = event.target as HTMLInputElement;
          target.focus();
          target.select();
        }}
      />

      <span className="">x</span>
      <FormikSelectView
        label=""
        id="unit"
        hideError
        disabled={disable}
        name={`${prefixName}.size.unit`}
        className={` w-full gap-0`}
        fieldClass="w-20 bg-none pr-0 "
        value={values[prefixName]?.size?.unit}>
        <option value="">Unit</option>
        <option value="inches">Inches</option>
        <option value="feet">Feet</option>
      </FormikSelectView>
    </div>
  );
};

export default SizeView;
