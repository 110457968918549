interface OrderItem {
  variant?: any;
  quantity?: any;
  inventory?: { quantity?: number };
  item?: {
    inventories?: { variant: string; quantity: number }[];
  };
}

export const sortOrderItems = (a: OrderItem, b: OrderItem): number => {
  const aVariant = a.variant ?? ''; // Fallback to an empty string if undefined
  const bVariant = b.variant ?? '';

  const aQty =
    (a.item?.inventories?.find((inv) => inv.variant === aVariant)?.quantity ??
      a.inventory?.quantity ??
      0) - (a.quantity ?? 0);

  const bQty =
    (b.item?.inventories?.find((inv) => inv.variant === bVariant)?.quantity ??
      b.inventory?.quantity ??
      0) - (b.quantity ?? 0);

  // Sorting logic: Negatives first
  if (aQty < 0 && bQty >= 0) return -1;
  if (aQty >= 0 && bQty < 0) return 1;
  return 0; // Maintain original order otherwise
};
