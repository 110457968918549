import {
  ReturnDetail,
  ReveiwReturnPackagePayload,
  ShipOrder,
  ShipOrderExtended,
} from 'types/shipOrders';
import { SortProps } from 'types/tables';
import { api, getApiURL } from './instance';
import { ApiResponseData, ApiResponseList } from 'types/common';
import { $session } from 'stores';
import { $site } from 'stores/site';

export const fetchShipOrders = async (params?: {
  page?: number;
  limit?: number;
  sortBy?: string;
  status?: number[];
  search?: string;
  isArchived?: boolean;
}) => {
  try {
    const { status = [], isArchived = false, ...otherParams } = params || {};
    const queryParams = {
      ...otherParams,
      status: status.map((data) => `${data}`).join(','),
      isArchived,
    };

    const { data } = await api.get<ApiResponseList<ShipOrder>>('/order/list', {
      params: queryParams,
    });
    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const fetchShipOrderById = async (id: string) => {
  try {
    const { data } = await api.get<ApiResponseData<ShipOrderExtended>>(
      `/order/get/${id}`,
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDamagedItemsById = async (id: string) => {
  try {
    const { data } = await api.get<ApiResponseList<ReturnDetail>>(
      `/item/damaged-items-list`,
      {
        params: { order: id },
      },
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateOrderToShipped = async (id: string) => {
  try {
    const { data } = await api.patch(`/order/process/${id}`);
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateOrderToProcessed = async (id: string) => {
  try {
    const { data } = await api.patch(`/order/ship/${id}`);
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const createOrder = async (payload: any) => {
  //remove unwanted fields from payload
  const {
    name,
    location,
    physicalAddress,
    billingAddress,
    contact,
    email,
    phone,
    rangeDate,
    package: _package,
    ..._payload
  } = payload;
  let advWarehouse = payload.advanceWarehouseDate;
  if (!advWarehouse.startDate && !advWarehouse.endDate) advWarehouse = null;

  const { data } = await api.post<any>(`/order/create`, {
    ..._payload,
    advanceWarehouseDate: advWarehouse,
  });
  return data;
};

export const editOrder = async ({
  payload,
  id,
}: {
  payload: any;
  id: string;
}) => {
  const data = await api.patch(`/order/edit/${id}`, {
    ...payload,
  });
  return data;
};

export const processOrder = async ({
  payload,
  id,
}: {
  payload: any;
  id: string;
}) => {
  //remove unwanted fields from payload
  const {
    name,
    location,
    physicalAddress,
    billingAddress,
    email,
    phone,
    contact,
    rangeDate,
    package: _package,
    ..._payload
  } = payload;

  let advWarehouse = payload.advanceWarehouseDate;
  if (!advWarehouse.startDate && !advWarehouse.endDate)
    advWarehouse = undefined;
  let packageDetail = _package;
  if (
    !packageDetail.size.weight &&
    !packageDetail.size.height &&
    !packageDetail.size.depth
  )
    packageDetail = undefined;
  const { data } = await api.patch<any>(`/order/process/${id}`, {
    ..._payload,
    advanceWarehouseDate: advWarehouse,
    package: packageDetail,
  });
  return data;
};

export const markOrderShipped = async ({ id }: { id: string }) => {
  const { data } = await api.patch<any>(`/order/ship/${id}`);
  return data;
};

export const markOrderComplete = async ({ id }: { id: string }) => {
  const { data } = await api.patch<any>(`/order/complete/${id}`);
  return data;
};

export const reviewReturnedPackage = async (
  orderId: string,
  payload: ReveiwReturnPackagePayload,
) => {
  const { data } = await api.patch<any>(`/order/return/${orderId}`, payload);
  return {
    ...data,
    ...payload,
  };
};

export const uploadItemImages = (
  files: File[],
  onProgress?: (progress: number) => void,
) => {
  return new Promise<string[]>((resolve, reject) => {
    if (files === undefined || files.length === 0) {
      Promise.resolve([]);
    }

    const formData = new FormData();

    files.forEach((file) => {
      formData.append('images', file);
    });

    const URL = getApiURL();

    const jwtToken = $session.actions.getTokens()?.accessToken || '';

    const url = `${URL}/item/upload-images`;

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);
    xhr.setRequestHeader('x-tenant-id', $site.actions.getTenant()!);
    xhr.setRequestHeader(
      'x-requestor-id',
      `${process.env.REACT_APP_X_REQUESTER_ID}`,
    );

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;

        onProgress?.(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        const response = JSON.parse(xhr.response);

        const files = response?.data as any;

        resolve(files);
      } else {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${
              JSON.parse(xhr.response)?.message
            }`,
          ),
        );
      }
    };

    xhr.send(formData);
  });
};

export const getCounts = async ({
  isArchived = false,
}: {
  isArchived: boolean;
}) => {
  const { data } = await api.get<{
    data: Array<{ _id: number; count: number }>;
  }>('/order/counts', { params: { isArchived } });

  return data?.data || [];
};

export const archiveOrderById = async (id: string) => {
  const { data } = await api.patch(`/order/archive/${id}`);
  return data.data;
};

export const deleteShipOrder = async (id: string) => {
  const { data } = await api.delete(`/order/delete/${id}`);
  return data.data;
};

export const updateShipOrderAccountInfo = async (id: string, payload: any) => {
  const { data } = await api.patch(`/order/account-info/${id}`, payload);
  return data.data;
};
