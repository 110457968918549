import React from 'react';
import { IconProps } from './iconProps';

const ArrowLeftIcon = ({ fill = 'none', w = '16', h = '16' }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill={fill}
      viewBox="0 0 16 16">
      <path
        fill="#373743"
        d="M3.826 8.997l4.9 4.9c.2.2.296.433.287.7-.008.267-.112.5-.312.7-.2.183-.434.279-.7.287a.916.916 0 01-.7-.287l-6.6-6.6a.877.877 0 01-.213-.325 1.107 1.107 0 01-.062-.375c0-.133.02-.258.062-.375a.877.877 0 01.213-.325l6.6-6.6a.933.933 0 01.687-.275c.275 0 .513.092.713.275.2.2.3.437.3.712 0 .275-.1.513-.3.713L3.826 6.997H15c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712a.968.968 0 01-.712.288H3.826z"></path>
    </svg>
  );
};

export default ArrowLeftIcon;
