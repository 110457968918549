// Extend the String prototype
declare global {
  interface String {
    with(id: string | number): string;
  }
}
String.prototype.with = function (id: string | number): string {
  if (typeof id === 'undefined' || id === null) {
    throw new Error('ID cannot be null or undefined');
  }
  return `${this}` + id;
};
// Add an export to make this a module
export {};
