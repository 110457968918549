import React from 'react';
import { IconProps } from './iconProps';

const Delete = ({
  fill = '#EE3854',
  w = '14',
  h = '14',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 14 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} cursor-pointer`}>
      <path
        d="M3.66668 1.66683V0.333496H10.3333V1.66683H13.6667V3.00016H12.3333V13.0002C12.3333 13.3684 12.0349 13.6668 11.6667 13.6668H2.33334C1.96516 13.6668 1.66668 13.3684 1.66668 13.0002V3.00016H0.333344V1.66683H3.66668ZM3.00001 3.00016V12.3335H11V3.00016H3.00001ZM5.00001 5.00016H6.33334V10.3335H5.00001V5.00016ZM7.66668 5.00016H9.00001V10.3335H7.66668V5.00016Z"
        fill="#555658"
      />
    </svg>
  );
};

export default Delete;
