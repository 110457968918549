import React from 'react';

import { RoutePaths } from './routes';
import { IconProps } from 'svg/iconProps';

import InventoryIcon from 'svg/inventoryIcon';
import DashboardIcon from 'svg/dashboardIcon';
import ProjectsIcon from 'svg/projectsIcon';
import OrderApproveyIcon from 'svg/orderApprove';
import PeopleIcon from 'svg/peopleIcon';
import FolderDataIcon from 'svg/folderDataIcon';
import SettingsIcon from 'svg/settingsIcon';

export type SubMenuItemProps = {
  path: string;
  itemTitle: string;
  getPath: () => string;
};

export type MenuItemProps = {
  title: string;
  Icon?: React.ElementType;
  path?: string;
  getPath?: () => string;
  items?: SubMenuItemProps[];
};

const IconWrapper = (Icon: any) => {
  return (props: IconProps) => <Icon {...props} />;
};

export const sideBarMenuItems: Array<MenuItemProps> = [
  {
    title: 'Dashboard',
    Icon: IconWrapper(DashboardIcon),
    path: RoutePaths.dashboard,
    getPath: () => `/dashboard`,
  },
  {
    title: 'Projects',
    Icon: IconWrapper(ProjectsIcon),
    items: [
      {
        path: RoutePaths.exhibit,
        itemTitle: 'Exhibit',
        getPath: () => `/projects/exhibits`,
      },
      {
        path: RoutePaths.productDisplay,
        itemTitle: 'Product Display',
        getPath: () => `/projects/product-display`,
      },
    ],
  },
  {
    path: RoutePaths.shiporders,
    title: 'Ship Orders',
    Icon: IconWrapper(OrderApproveyIcon),
    getPath: () => `/ship-orders`,
  },
  {
    title: 'Inventory',
    Icon: IconWrapper(InventoryIcon),
    items: [
      {
        path: RoutePaths.items,
        itemTitle: `Items`,
        getPath: () => `/inventory/items`,
      },
      {
        path: RoutePaths.itemCategories,
        itemTitle: `Items Categories`,
        getPath: () => `/inventory/items-categories`,
      },
    ],
  },
  {
    title: 'People',
    Icon: IconWrapper(PeopleIcon),
    path: RoutePaths.people,
    getPath: () => `/people`,
    items: [
      {
        path: RoutePaths.employees,
        itemTitle: `Employees`,
        getPath: () => `/people/employees`,
      },
      {
        path: RoutePaths.clients,
        itemTitle: `Clients`,
        getPath: () => `/people/clients`,
      },
    ],
  },
  {
    title: 'Global Data',
    Icon: IconWrapper(FolderDataIcon),
    path: RoutePaths.globaldata,
    getPath: () => `/global-data`,
    items: [
      {
        path: RoutePaths.vendors,
        itemTitle: `Vendors`,
        getPath: () => `/global-data/vendors`,
      },
      {
        path: RoutePaths.events,
        itemTitle: `Events`,
        getPath: () => `/global-data/events`,
      },
      {
        path: RoutePaths.venue,
        itemTitle: `Venue`,
        getPath: () => `/global-data/venue`,
      },
    ],
  },
  {
    path: RoutePaths.settings,
    title: 'Settings',
    Icon: IconWrapper(SettingsIcon),
    getPath: () => `/settings`,
  },
];

export const NavItems = [
  {
    path: RoutePaths.dashboard,
    title: 'Dashboard',
    Icon: <span className="material-icons">dashboard</span>,
    getPath: () => `/dashboard`,
  },
  {
    path: RoutePaths.projects,
    title: 'Projects',
    Icon: <span className="material-icons">work</span>,
    getPath: () => `/projects`,
  },
  {
    path: RoutePaths.shiporders,
    title: 'Ship Orders',
    Icon: <OrderApproveyIcon />,
    getPath: () => `/ship-orders`,
  },
  {
    path: RoutePaths.inventory,
    title: 'Inventory',
    Icon: <InventoryIcon />,
    getPath: () => `/inventory`,
  },
  {
    path: RoutePaths.people,
    title: 'People',
    Icon: <span className="material-icons">groups</span>,
    getPath: () => `/people`,
  },
  {
    path: RoutePaths.globaldata,
    title: 'Global Data',
    Icon: <FolderDataIcon />,
    getPath: () => `/global-data`,
  },
  {
    path: RoutePaths.settings,
    title: 'Settings',
    Icon: <span className="material-icons">settings</span>,
    getPath: () => `/settings`,
  },
];
