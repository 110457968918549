import { useRef } from 'react';

class ModalReference {
  ref = useRef<HTMLDialogElement>(null);

  constructor(ref?: any) {
    this.ref = ref || useRef<HTMLDialogElement>(null);
  }

  open = () => {
    this.ref.current?.showModal();
  };
  close = () => {
    this.ref.current?.close();
  };
}

export default ModalReference;
