import { Item, ItemDetails, ItemSku } from 'types/item';

import { SortProps } from 'types/tables';
import { itemSku } from 'utils/mockData/inventory';
import { api, getApiURL } from './instance';
import { ApiResponseData, ApiResponseList } from 'types/common';
import { $session } from 'stores';
import { ProjectItemType } from 'types/project';

export const fetchItems = async (params?: {
  page?: number;
  limit?: number;
  sortBy?: string;
  search?: string;
  clients?: any;
  clientId?: string;
  categories?: any;
  type?: ProjectItemType;
  isArchived?: boolean;
}) => {
  try {
    const {
      sortBy,
      search = '',
      categories,
      clients,
      clientId,
      isArchived,
      type,
      ...dataQuery
    } = params || {};

    const queryParams: any = {
      ...dataQuery,
      sortBy: sortBy || 'name:asc',
      isArchived: isArchived ? 1 : 0,
      categories: categories?.length > 0 ? categories.join(',') : undefined,
      clientId: clientId || undefined,
      clients: clients?.length > 0 ? clients.join(',') : undefined,
      search: search || undefined,
      type: type,
    };

    // Make the API request
    const { data } = await api.get<ApiResponseList<Item>>('item/list', {
      params: queryParams,
    });

    // Return the list of items from the response
    return data.data;
  } catch (error) {
    throw error; // Optionally re-throw the error to handle it higher up the call chain
  }
};

export const fetchDamagedItems = async (params?: {
  page?: number;
  limit?: number;
  sortBy?: string;
  search?: string;
  clients?: string[];
  isArchived?: boolean;
  categories?: string[];
  type?: 1 | 2;
}) => {
  try {
    const {
      sortBy,
      search = '',
      categories,
      clients,
      isArchived,
      ...dataQuery
    } = params || {};

    // Construct the query parameters
    let queryParams: any = {
      ...dataQuery,
      sortBy: sortBy || 'name:asc',
      isArchived: isArchived ? 1 : 0,
    };

    if (categories && categories.length > 0) {
      queryParams.categories = categories.join(',').toString();
    }

    if (clients && clients.length > 0) {
      queryParams.clients = clients.join(',').toString();
    }

    // Add search to queryParams if provided
    if (search) {
      queryParams.search = search;
    }

    // Make the API request
    const { data } = await api.get<ApiResponseList<Item>>(
      'item/damaged-items-list',
      {
        params: queryParams,
      },
    );

    // Return the list of items from the response
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchItemDetailsById = async (id: string) => {
  try {
    const { data } = await api.get<ApiResponseData<ItemDetails>>(
      `/item/detail/${id}`,
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export type ItemCategoryParam = {
  search?: string;
  sortBy?: string;
  category?: string;
  enabled?: boolean;
};

export const fetchItemsCategories = async (params?: ItemCategoryParam) => {
  const { data } = await api.get('category/list', {
    params: params,
  });
  return data.data.items;
};

export const fetchItemSku = async (
  sort?: SortProps<ItemSku>,
): Promise<ItemSku[]> => {
  const getItems = () => {
    if (!sort) return itemSku;

    return itemSku.sort((a: ItemSku, b: ItemSku) => {
      const FACTOR = sort.order === 'asc' ? -1 : 1;
      const FIELD = sort.field;
      const A = a[FIELD];
      const B = b[FIELD];

      if (A < B) return FACTOR * -1;
      if (A > B) return FACTOR * 1;

      return 0;
    });
  };

  return new Promise((resolve) => {
    setTimeout(() => resolve(getItems()), 1000);
  });
};
export const adjutStock = async (payload: any) => {
  const { data } = await api.post<any>(`/item/adjust-stock`, payload);
  return data;
};

export const createItem = async (payload: any) => {
  const { data } = await api.post<any>(`/item/create`, payload);
  return data;
};

export const editItem = async (payload: any) => {
  const { data } = await api.patch<any>(`/item/edit/${payload.id}`, payload);
  return data;
};

export const uploadItemImages = (
  files: File[],
  onProgress?: (progress: number) => void,
) => {
  return new Promise<string[]>((resolve, reject) => {
    if (files === undefined || files.length === 0) {
      Promise.resolve([]);
    }

    const formData = new FormData();

    files.forEach((file) => {
      formData.append('images', file);
    });

    const URL = getApiURL();

    const jwtToken = $session.actions.getTokens()?.accessToken || '';

    const url = `${URL}/item/upload-images`;

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;

        onProgress?.(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        const response = JSON.parse(xhr.response);

        const files = response?.item as string[];

        resolve(files);
      } else {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${
              JSON.parse(xhr.response)?.message
            }`,
          ),
        );
      }
    };

    xhr.send(formData);
  });
};

export const archiveItemById = async (id: string) => {
  try {
    const { data } = await api.patch(`/item/archive/${id}`);
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchItemAdjustQuantityHistory = async (id: string) => {
  try {
    const { data } = await api.get(`item/${id}/adjust-quantity-history`);
    return data.data;
  } catch (error) {
    console.log(error);
  }
};
