import React, { FC, useRef } from 'react';

import { useField } from 'formik';

import DatePicker from 'react-datepicker';
import { cn } from 'utils';

import 'react-datepicker/dist/react-datepicker.css';

import { FormikDatepickerProps } from './FormikDatepicker.props';
import { format } from 'date-fns';
import './FormikDatepicker.style.css';
import Text from '../Text';
import { formatDate } from 'utils/date';
import { setHours, setMinutes } from 'date-fns';

const FormikDatepickerView: FC<
  FormikDatepickerProps & { onDateChange?: (date: String | null) => void }
> = ({
  name,
  label,
  error,
  labelProps,
  helperText,
  isEditable,
  isRequired,
  containerProps,
  wrapperClass,
  onDateChange,
  inputContainer,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ value }, meta, helpers] = useField(name);

  const displayValue = formatDate(value, 'MM/dd/yyyy');

  const selected = !!displayValue ? new Date(displayValue) : null;

  const handleOnChange = (date: Date | null) => {
    if (date) {
      // Set the time to 12:00 PM in the local time zone
      const adjustedDate = setHours(setMinutes(date, 0), 12);
      helpers.setValue(adjustedDate);

      if (onDateChange) {
        let functionDateData = adjustedDate.toISOString().split('T')[0];
        onDateChange(functionDateData);
      }
      if (!isEditable && inputRef.current) {
        inputRef.current.value = format(adjustedDate, 'MM/dd/yyyy');
      }
    } else {
      helpers.setValue(null);
      if (onDateChange) {
        onDateChange(null);
      }
    }
  };

  const moreProps = isEditable
    ? {
        onSelect: (date: Date | null) => {
          if (inputRef.current && inputRef.current?.value && !!date) {
            inputRef.current.value = format(new Date(date), 'MM/dd/yyyy');
          }
        },
        onChangeRaw: (e: React.FocusEvent<HTMLInputElement, Element>) => {
          const newRaw = new Date(e.target.value);

          if (newRaw instanceof Date && !isNaN(newRaw as any)) {
            helpers.setValue(newRaw);
            if (inputRef.current) {
              inputRef.current.value = format(new Date(newRaw), 'MM/dd/yyyy');
            }
          }
        },
      }
    : {};
  return (
    <div className={cn('mt-3', containerProps)}>
      {label && (
        <label htmlFor={name} className={cn('mt-3', labelProps)}>
          {label}
        </label>
      )}
      <div className={cn('flex flex-col', wrapperClass)}>
        <DatePicker
          wrapperClassName="formik-datepicker"
          toggleCalendarOnIconClick
          disabledKeyboardNavigation
          onChangeRaw={(e) => e.preventDefault()}
          disabled={props.disabled || false}
          selected={selected}
          onChange={handleOnChange}
          customInput={
            <input
              type="text"
              ref={inputRef}
              id="date-picker-input"
              defaultValue={displayValue}
              readOnly={isEditable}
              className={cn(
                `mt-2 text-jakarta input input-bordered w-full max-w-xs ${inputContainer}`,
              )}
            />
          }
          {...props}
        />
        {(error || meta.error) && (
          <Text className="text-red-500">
            {(meta.touched && meta.error) || error}
          </Text>
        )}
      </div>
    </div>
  );
};

export default FormikDatepickerView;
