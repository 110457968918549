import React, { FC } from 'react';

import { FormikInputProps } from './FormikInput.props';
import { Tooltip } from 'react-daisyui';
import Info from 'assets/SVG/info';
import Text from '../Text';

import { cn } from 'utils';
import { Field, FieldProps, useField } from 'formik';
import { classes } from 'utils/tailwind';

const FormikInputView: FC<FormikInputProps> = ({
  className,
  tooltip,
  label,
  hideError = false,
  readonly = false,
  justifyText = 'text-left',
  onChange,
  name,
  disabled,
  containerClassName,
  required,
  ...inputProps
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <div className={classes([containerClassName ?? '', 'space-y-2'])}>
      {label && (
        <div>
          <label
            className={classes([required ? 'required' : ''])}
            htmlFor={inputProps.id}>
            {label}
          </label>
          {tooltip && (
            <Tooltip message={tooltip}>
              <Info />
            </Tooltip>
          )}
        </div>
      )}

      <Field name={name}>
        {({ field }: FieldProps) => (
          <input
            {...field}
            disabled={disabled}
            readOnly={readonly || false}
            name={name}
            {...inputProps}
            className={cn(`input input-bordered w-full ${justifyText}`)}
          />
        )}
      </Field>
      {!hideError && meta.error && meta.touched && (
        <Text className="text-red-500  mt-1">{meta.error}</Text>
      )}
    </div>
  );
};

export default FormikInputView;
