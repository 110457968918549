import React, { FC, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';

import Input from 'components/primitives/Input';
import FormikTextArea from 'components/primitives/FormikTextArea';
import Size from 'components/primitives/Size';
import { GeneralInfoProps } from '../OrderForm.props';
import FormikErrorMessage from 'components/core/FormikErrorMessage';
import LeftLabeledComponent from 'components/primitives/LeftLabeledComponent';
import FormikInput from 'components/primitives/FormikInput';
import { classes } from 'utils/tailwind';

const SizeWeight: FC<GeneralInfoProps> = ({
  mode,
  prefixName = 'package',
  index,
  containerClassName,
  disable = false,
}) => {
  const { setFieldValue, values, errors } = useFormikContext<any>();

  const isCreateMode = mode == 'create';

  return (
    <div
      className={classes([
        containerClassName ?? '',
        'bg-primary-300 p-5 gap-4 flex flex-col w-full',
      ])}>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 justify-start">
        <div className="">
          <div className="flex flex-col gap-4">
            <label
              htmlFor={`${prefixName}.size`}
              className={classes(['required w-[160px]'])}>
              Size
            </label>
            <Size
              prefixName={prefixName}
              index={index}
              disable={disable}
              mode={mode}
            />
          </div>
          <div className="mt-1">
            {['width', 'height', 'depth', 'unit'].map((field) => (
              <FormikErrorMessage name={`${prefixName}.size.${field}`} />
            ))}
          </div>
        </div>
        <FormikInput
          name={`${prefixName}.weight`}
          type="number"
          placeholder="lbs"
          label="Weight"
          required
          disabled={disable}
          onClick={(event) => {
            const target = event.target as HTMLInputElement;
            target.focus();
            target.select();
          }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
        <FormikInput
          name={`${prefixName}.transactionNumber`}
          type="text"
          label="Tracking Number"
          disabled={disable}
          labelContainer={isCreateMode ? '' : 'required'}
          required
          placeholder="eg: 1234567890"
          onClick={(event) => {
            const target = event.target as HTMLInputElement;
            target.focus();
            target.select();
          }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </div>

      <FormikTextArea
        className="w-full rounded-lg"
        disabled={disable}
        name={`${prefixName}.notes`}
        label="Notes"
        required
        id={`${prefixName}.notes`}
        rows={4}
        placeholder="Add any additional information here (Max. 1200 characters)..."
      />
    </div>
  );
};

export default SizeWeight;
